















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { listFiles } from "@/api/fileService";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "Diplomas",
  components: { LoadingSpinner },
  setup() {
    const vm = reactive({
      diplomas: [] as Learnlink.File.Reference[],
      loading: false,
    });

    onMounted(async () => {
      vm.loading = true;

      let diplomas = await listFiles("diplomas", userModule.state.userId);

      for (const studentUID of userModule.state.user.students) {
        const studentDiplomas = await listFiles("diplomas", studentUID);
        diplomas = [...diplomas, ...studentDiplomas];
      }

      vm.loading = false;
      vm.diplomas = diplomas;
    });

    return {
      vm,
    };
  },
});
